<template>
  <!-- 提现-出纳 -->
  <div class="container">
    <a-tabs :default-active-key="tabIndex" :animated="false" @change="callback">
      <a-tab-pane :key="0" tab="提现出纳">
        <!-- 提现待审批-start -->
        <div class="main-content">
          <div class="main-content-header">
            <div class="item">
              <div class="title">提现待出纳</div>
            </div>
            <div class="item">
              <a-button icon="download"> 导出订单账目 </a-button>
            </div>
          </div>
          <my-table
            :columns="columns1"
            :data-source="dataSource1"
            :loading="loading1"
            :pagination="pagination1"
            :scroll="{ x: 1350, y: true }"
            @change="handlePaginationChange1"
          >
            <template slot="status" slot-scope="record">
              <span>{{ typeText[record.userType] }}</span>
            </template>
            <template slot="price" slot-scope="record">
              <a-statistic
                class="a-statistic"
                prefix="￥"
                :value="record.price"
              />
            </template>
            <template slot="status" slot-scope="record">
              <span>{{ statuText[record.status] }}</span>
            </template>
            <template slot="action" slot-scope="record">
              <router-link
                :to="{
                  path: '/withdrawalApproveCashierInfo',
                  query: { id: record.id }
                }"
              >
                详情
              </router-link>
            </template>
          </my-table>
        </div>

        <!-- 提现待审批-end -->
      </a-tab-pane>

      <a-tab-pane :key="1" tab="审批记录">
        <!-- 审批记录-start -->
        <div class="main-content">
          <my-table
            :columns="columns2"
            :data-source="dataSource2"
            :loading="loading2"
            :pagination="pagination2"
            :scroll="{ x: 1350, y: true }"
            @change="handlePaginationChange2"
          >
            <template slot="userType" slot-scope="record">
              <span>{{ typeText[record.userType] }}</span>
            </template>
            <template slot="price" slot-scope="record">
              <a-statistic
                class="a-statistic"
                prefix="￥"
                :value="record.price"
              />
            </template>
            <template slot="status" slot-scope="record">
              <span>{{ castatusText[record.castatus] }}</span>
            </template>
            <template slot="action" slot-scope="record">
              <router-link
                :to="{
                  path: '/withdrawalApproveCashierInfo',
                  query: { id: record.id }
                }"
              >
                详情
              </router-link>
            </template>
          </my-table>
        </div>
        <!-- 审批记录-end -->
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabIndex: 0,
      dataSource1: [],
      dataSource2: [],
      columns1: [
        // 待审核
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' },
          width: 150
        },
        {
          title: '系统账号',
          dataIndex: 'systemNum',
          width: 250
        },
        {
          title: '账号使用者',
          dataIndex: 'accoutUser',
          width: 150
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          width: 200
        },
        {
          title: '提现金额',
          scopedSlots: { customRender: 'price' },
          width: 200
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      columns2: [
        // 记录
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '账号类型',
          scopedSlots: { customRender: 'userType' },
          width: 100
        },
        {
          title: '系统账号',
          dataIndex: 'systemNum',
          width: 250
        },
        {
          title: '提现用户',
          dataIndex: 'accoutUser',
          width: 150
        },
        {
          title: '提现用户手机号',
          dataIndex: 'mobile',
          width: 200
        },
        {
          title: '提现金额',
          scopedSlots: { customRender: 'price' },
          width: 200
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading1: true,
      loading2: true,
      pagination1: {
        current: 1,
        total: 0
      },
      pagination2: {
        current: 1,
        total: 0
      },
      typeText: {
        admin: '平台',
        proxy: '代理企业',
        salesman: '业务员',
        madmin: '中台管理账号',
        servicer: '作业师傅',
        lector: '理论讲师',
        training: '实操讲师',
        apprentice: '学徒',
        visitiservicer: '上门实训师傅',
        member: '注册用户',
        business: '渠道商',
        bterminal: '渠道商终端',
        community: '渠道经理'
      },
      statuText: {
        0: '会计审核',
        1: '出纳审核',
        2: '会计审核拒绝',
        3: '出纳打款中',
        4: '出纳审核拒绝',
        5: '提现成功'
      },
      castatusText: {
        0: '出纳审批中',
        1: '出纳已审批',
        2: '出纳已拒绝'
      }
    }
  },
  created() {
    this.getWithdrawalApproveCashier()
  },
  methods: {
    callback(key) {
      // tab切换
      this.tabIndex = key
      if (key && !this.dataSource2.length) {
        this.getWithdrawalRecordCashier()
      }
    },
    getWithdrawalApproveCashier() {
      // 获取提现待审核列表
      const data = {
        page: this.pagination1.current,
        limit: 10
      }
      this.$axios.getWithdrawalApproveCashier(data).then((res) => {
        this.dataSource1 = res.data.data.list
        this.pagination1.total = res.data.data.count
        this.loading1 = false
      })
    },
    handlePaginationChange1(e) {
      // 提现待审核列表分页
      this.loading1 = true
      this.pagination1.current = e.current
      this.getWithdrawalApproveCashier()
    },
    getWithdrawalRecordCashier() {
      // 获取提现待审核列表
      const data = {
        page: this.pagination2.current,
        limit: 10
      }
      this.$axios.getWithdrawalRecordCashier(data).then((res) => {
        this.dataSource2 = res.data.data.list
        this.pagination2.total = res.data.data.count
        this.loading2 = false
      })
    },
    handlePaginationChange2(e) {
      // 提现待审核列表分页
      this.loading2 = true
      this.pagination2.current = e.current
      this.getWithdrawalRecordCashier()
    }
  }
}
</script>

<style scoped>
.ant-tabs {
  height: 100%;
}
</style>
